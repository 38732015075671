import Productos from "./Paginas/Productos";
import Inicio from "./Paginas/Inicio";
import Industria from "./Paginas/Industria";
import MarketingMaterials from "./Paginas/MarketingMaterials";
import SignsAndBanners from "./Paginas/SignsAndBanners";
import Apparel from "./Paginas/Apparel";
import WebpressPrinting from "./Paginas/WebpressPrinting";
import InvitationsAndStationery from "./Paginas/InvitationsAndStationery";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import ContactUs from "./Paginas/ContactUs";
import {IntlProvider} from 'react-intl';
import { langContext } from "./context/langContext";
import React, {useContext} from "react";

const App = () => {
  const idioma = useContext(langContext);

  console.log(idioma);

  return (
    <IntlProvider locale="es-MX" messages={{}}>
    <BrowserRouter>
      <div>
        <Routes>
          <Route exact path="/" element={<Inicio />} />
          <Route path="/industry" element={<Industria />} />
          <Route path="/products" element={<Productos />} />
          <Route path="/marketingMaterials" element={<MarketingMaterials />} />
          <Route path="/signsAndBanners" element={<SignsAndBanners />} />
          <Route path="/apparel" element={<Apparel />} />
          <Route path="/webpressPrinting" element={<WebpressPrinting />} />
          <Route path="/invitationsAndStationery" element={<InvitationsAndStationery />} />
          <Route path="/contactUs" element={<ContactUs/>}/>
        </Routes>
      </div>
    </BrowserRouter>
    </IntlProvider>
  );
};

export default App;