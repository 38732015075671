import React from 'react';
import Navbar from '../componentes/Navbar';
import Popular from '../componentes/PopularSection/Popular';
import Slider from '../componentes/Slider';
import PieDePagina from '../componentes/PieDePagina';
import MenuNavbar from '../componentes/BarraNavegacion/MenuNavbar';
import Newsletter from '../componentes/Newsletter/Newsletter';

const Inicio = () => {
  return (
    <div>
        <Navbar/>
        <MenuNavbar/>
        <Slider/>       
        <Newsletter/>
        <PieDePagina/>
    </div>
  )
}

export default Inicio;