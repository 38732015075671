import { useState } from 'react';
import { decode } from 'html-entities';
import styled from 'styled-components';
import { Send } from '@material-ui/icons';

const ContenedorGeneral = styled.div`
height: 60vh;
background-color: #EFF4FA;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
`

const Titulo = styled.h1`
font-size: 40px;
margin-bottom: 20px;
text-align: center;
`
const Descripcion = styled.div`
font-size: 24px;
font-weight: 300;
margin-bottom: 20px;
text-align: center;
`

const Input = styled.input`
border: none;
flex: 8;
padding-left: 20px;
align-items:center;
width: 400px;
`
const Boton = styled.button`
flex: 1;
border: none;
background-color: #355c91;
color: white;
cursor: pointer;
`
const InputContenedor = styled.div`
width: 100%;
height: 40px;
background-color: white;
display: flex;
position: center;
`


const NewsletterForm = ( { status, message, onValidated }) => {

  const [ error, setError ] = useState(null);
  const [ email, setEmail ] = useState(null);

  /**
   * Handle form submit.
   *
   * @return {{value}|*|boolean|null}
   */
  const handleFormSubmit = () => {

    setError(null);

    if ( ! email ) {
      setError( 'Please enter a valid email address' );
      return null;
    }

    const isFormValidated = onValidated({ EMAIL: email });

    // On success return true
    return email && email.indexOf("@") > -1 && isFormValidated;
  }

  /**
   * Handle Input Key Event.
   *
   * @param event
   */
  const handleInputKeyEvent = ( event ) => {
    setError(null);
    // Number 13 is the "Enter" key on the keyboard
    if (event.keyCode === 13) {
      // Cancel the default action, if needed
      event.preventDefault();
      // Trigger the button element with a click
      handleFormSubmit();
    }
  }

  /**
   * Extract message from string.
   *
   * @param {String} message
   * @return {null|*}
   */
  const getMessage = (message) => {
    if ( !message ) {
     return null;
    }
    const result = message?.split('-') ?? null;
    if ( "0" !== result?.[0]?.trim() ) {
     return decode(message);
    }
    const formattedMessage = result?.[1]?.trim() ?? null;
    return formattedMessage ? decode( formattedMessage ) : null;
  }

  return (
    <>
    <ContenedorGeneral>
      <div className="d-flex newsletter-input-fields">
        <Titulo>Newsletter</Titulo>
        <Descripcion>Join our newsletter to get updates</Descripcion>
        <InputContenedor>
          <Input
            onChange={(event) => setEmail(event?.target?.value ?? '')}
            type="email"
            placeholder="Your email"
            
            onKeyUp={(event) => handleInputKeyEvent(event)}
          />
          <Boton className="wp-block-button__link" onClick={handleFormSubmit}>
            <Send/>
          </Boton>
        </InputContenedor>
      </div>
      <div className="newsletter-form-info">
        {status === "sending" && <div>Sending...</div>}
        {status === "error" || error ? (
          <div
            className="newsletter-form-error"
            dangerouslySetInnerHTML={{ __html: error || getMessage( message ) }}
          />
        ) : null }
        {status === "success" && status !== "error" && !error && (
          <div dangerouslySetInnerHTML={{ __html: decode(message) }} />
        )}
      </div>
      </ContenedorGeneral>
    </>
  );
}

export default NewsletterForm;