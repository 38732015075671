import React from 'react';
import styled from 'styled-components';
import emailjs from 'emailjs-com';
import Alerta from './../componentes/elementos/Alerta';
import { useState } from 'react';
import Navbar from '../componentes/Navbar';
import MenuNavbar from '../componentes/BarraNavegacion/MenuNavbar';
import Newsletter from '../componentes/Newsletter/Newsletter';
import PieDePagina from '../componentes/PieDePagina';
import { MailOutline, Phone, Room } from '@material-ui/icons';

const ContenedorInfo = styled.div`
`

const Titulo = styled.h1`
font-size: 30px;
text-align: center;
margin-top: 20px;
margin-bottom: 20px;
`
const ContactoItem = styled.p`
margin-top: 40px;
margin-bottom: 20px;
align-items: center;
text-align: center;
font-size: 20px;
font-weight: bold;
color: #08298A;
`
const ParrafoInfo = styled.p`
text-align: center;
font-size: 18px;
color: black;
`
const ContenedorFormulario = styled.div`
display: flex;
align-items: center;
justify-content: center;
`
const Wrapper = styled.div`
margin-top: 30px;
width: 30%;
padding: 20px;
background-color: white;
`
const TituloFormulario = styled.h1`
margin-top: 30px;
font-size: 20px;
font-weight: 300;
`
const Forma = styled.form`
display: flex;
flex-direction: column;
`
const Input = styled.input`
flex: 1;
min-width: 40%;
margin: 20px 10px 0px 0px;
padding: 10px;
color: black;
`
const Mensaje = styled.textarea`
flex: 1;
min-width: 40%;
margin: 20px 10px 0px 0px;
padding: 10px;
color: black;
`
const Boton = styled.button`
width: 40%;
border: none;
padding: 15px 20px;
color: Black;
background-color: #D8D8D8;
cursor: pointer;
margin-top: 20px;
`
const LinkMaps = styled.a`
text-decoration: none;
color: black;
`
const Subtitulos = styled.p`
font-size: 25px;
text-align: center;
margin-top: 30px;
font-weight: bold;
color: #08298A;
`

//Conexión a los servicios de Emailjs para envíos de mansajes.
const ContactUs = () => {

  const [email, establecerEmail] = useState('');
  const [estadoAlerta, cambiarEstadoAlerta] = useState(false);
  const [alerta, cambiarAlerta] = useState({});
  const [name, establecerNombre] = useState('');
  const [message, establecerMensaje] = useState('');

  const handleChange = (e) => {
    switch(e.target.name){
      case 'name':
        establecerNombre(e.target.value);
        break;
      case 'email':
        establecerEmail(e.target.value);
        break;
      case 'message':
        establecerMensaje(e.target.value);
        break;
      default:
        break;
    }
  }

  function sendEmail(e){
    e.preventDefault();
    cambiarEstadoAlerta(false);
    cambiarAlerta({});

    emailjs.sendForm('service_n029nvz', 'template_pw4snig', e.target, 'MxZeBL5zcFhw2IhN7'
    ).then(res =>{
      console.log(res);

    }).catch(err => console.log(err));

    //comprobación del correo de lado del cliente.
    const expresionRegular = /[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+/;
    if (!expresionRegular.test(email)){
      cambiarEstadoAlerta(true);
      cambiarAlerta({
        tipo: 'error',
        mensaje: 'Please enter a valid email'
      });
      return;

    }

    //comprobación de espacios vacíos
    if (name === '' || email === '' || message === ''){
      cambiarEstadoAlerta(true);
      cambiarAlerta({
        tipo: 'error',
        mensaje: 'Please fill in all the fields'
      });
      return;
    } else{
      cambiarEstadoAlerta(true);
      cambiarAlerta({
        tipo: 'exito',
        mensaje: 'Message sent'
      });
      return;
    }
  }

  return (
    <div>
      <Navbar/>
      <MenuNavbar/>
      <ContenedorInfo>
          <Titulo> Contact Us</Titulo>
          <hr color='#D7DBDD'/>
          <Subtitulos>USA Contact</Subtitulos>
          <ContactoItem><Room style={{marginRight: "10px"}}/>
            Address
            <ParrafoInfo>
            <LinkMaps href={'https://goo.gl/maps/FusAmqpFhodq5nJZ6'}>
            676 Archibald St, San Luis, AZ 85349, EE. UU.
            </LinkMaps>
            </ParrafoInfo>
            {/* <ParrafoInfo>Hidalgo y Calle 36, San Luis Río Colorado, Sonora, México.</ParrafoInfo> */}
          </ContactoItem>
          <ContactoItem><Phone style={{marginRight: "10px"}}/> 
            Phone
            <ParrafoInfo>+1 (928)941-8255</ParrafoInfo>
          </ContactoItem>
          <ContactoItem><MailOutline style={{marginRight: "10px"}}/>
            Email
            <ParrafoInfo>mmonge@impresorasuperior.com</ParrafoInfo>
          </ContactoItem>
          <hr color='#D7DBDD'/>
          <Subtitulos>Mexico Contact</Subtitulos>
          <ContactoItem><Room style={{marginRight: "10px"}}/>
            Address
            <ParrafoInfo>
            <LinkMaps href={'https://www.google.com.mx/maps/place/C.+36+%26+Av.+Miguel+Hidalgo,+Bur%C3%B3crata,+83450+San+Luis+R%C3%ADo+Colorado,+Son./@32.4666303,-114.7395861,17z/data=!3m1!4b1!4m5!3m4!1s0x80d64f95f2cad33f:0x35cc6a49a0c0e88c!8m2!3d32.4666258!4d-114.7373974'}>
              Hidalgo y Calle 36, San Luis Río Colorado, Sonora, México.
            </LinkMaps>
            </ParrafoInfo>
            {/* <ParrafoInfo>Hidalgo y Calle 36, San Luis Río Colorado, Sonora, México.</ParrafoInfo> */}
          </ContactoItem>
          <ContactoItem><Phone style={{marginRight: "10px"}}/> 
            Phone
            <ParrafoInfo>(653)535-9397</ParrafoInfo>
            <ParrafoInfo>(653)535-9398</ParrafoInfo>
            <ParrafoInfo>(653)130-0035</ParrafoInfo>
          </ContactoItem>
          <ContactoItem><MailOutline style={{marginRight: "10px"}}/>
            Email
            <ParrafoInfo>viviana@impresorasuperior.com</ParrafoInfo>
          </ContactoItem>
          <hr color='#D7DBDD'/>
      </ContenedorInfo>
      <ContenedorFormulario>
          <Wrapper>
          <p style={{color: "#848484"}}>
          If you want more information about any of our products or services, fill out our form to contact you.
          </p>
          <TituloFormulario>Send us an email</TituloFormulario>
          <Forma onSubmit={sendEmail}>
          <Input 
              type="text"
              name="name"
              placeholder="Name"
              value={name}
              onChange={handleChange}
          />
          <Input 
              type="email"
              name="email"
              placeholder="Email"
              value={email}
              onChange={handleChange}
          />
          <Mensaje
              name="message"
              placeholder="Message"
              rows={4}
              value={message}
              onChange={handleChange}
          />
          <Boton as= "button" primario type="submit">SEND</Boton>
          </Forma>
          <Alerta
                tipo ={alerta.tipo}
                mensaje ={alerta.mensaje}
                estadoAlerta = {estadoAlerta}
                cambiarEstadoAlerta = {cambiarEstadoAlerta}
          />

          </Wrapper>
        
      </ContenedorFormulario>
    <Newsletter/>
    <PieDePagina/>
    </div>
  )
}

export default ContactUs;
