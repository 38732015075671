import superiorPrintingLogo from './Imagenes/img/superiorPrintingLogo.jpg';
import logoImpresora from './Imagenes/img/logoImpresora.png';
import Poster from './Imagenes/img/Poster.png';
import Volantes from './Imagenes/img/Volantes.png';
import slider1 from './Imagenes/img/slider1.PNG';
import slider2 from './Imagenes/img/slider2.PNG';
import slider3 from './Imagenes/img/slider3.PNG';


export const elementosSlider = [
    {
        id: 1,
        imagen: slider1,
        titulo: "Choose your design, customize it!",
        descripcion: "We are your best option in t-shirt printing. You choose the design! We have a variety of sizes and colors. Quote with us!",
        bg: "white",

    },

    {
        id: 2,
        imagen: slider2,
        titulo: "Publish your brand with advertising magnets!",
        descripcion: "We are the best in printing advertising or personalized magnets as you want.",
        bg: "white",
    },

    {
        id: 3,
        imagen: slider3,
        titulo: "Make your company a safe place!",
        descripcion: "We have security signage printing for different types of industries.",
        bg: "white",
    }
];

export const populares = [
    {
        id: 1,
        imagen: Poster,
        titulo: "Posters",
    },
    {
        id: 2,
        imagen: Volantes,
        titulo: "Flyers",
    },
    {
        id: 3,
        imagen: "https://dqj17tese79do.cloudfront.net/graficadr/images/products_gallery_images/folletos-png-4.png",
        titulo: "Brochures",
    }
];



export const LogotipoMexico = [
    {
        "logo_mex": logoImpresora,
    },  
];

export const LogotipoUSA = [
    {
        "logo_usa": logoImpresora,
    },  
];









