import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {Provider} from "react-redux";
import store from "./redux/store";
import {LangProvider} from './context/langContext';

ReactDOM.render(
  <Provider store={store}>
    <LangProvider>
      <App />
    </LangProvider>
  </Provider>,
  document.getElementById('root')
);