import React from 'react';
import styled from "styled-components";
import Navbar from '../componentes/Navbar';
import MenuNavbar from '../componentes/BarraNavegacion/MenuNavbar';
import Newsletter from '../componentes/Newsletter/Newsletter';
import PieDePagina from '../componentes/PieDePagina';
import ProdInvitations from '../componentes/ProductosInvitations/ProdInvitations';


const Contenedor = styled.div``
const Title = styled.h1`
margin: 20px;
`

const InvitationsAndStationery = () => {
  return (
    <Contenedor>
        <Navbar/>
        <MenuNavbar/>
        <Title>Invitations and Stationery</Title>
        <ProdInvitations/>
        <Newsletter/>
        <PieDePagina/>
    </Contenedor>
  )
}

export default InvitationsAndStationery