import React from 'react';
import styled from "styled-components";
import Navbar from '../componentes/Navbar';
import MenuNavbar from '../componentes/BarraNavegacion/MenuNavbar';
import Newsletter from '../componentes/Newsletter/Newsletter';
import PieDePagina from '../componentes/PieDePagina';
import ProdApparel from '../componentes/ProductosApparel/ProdApparel';
import ProdWebpressPrinting from '../componentes/ProductosWebpressPrinting/ProdWebpressPrinting';
import ProdInvitations from '../componentes/ProductosInvitations/ProdInvitations';
import ProdMktMaterials from '../componentes/ProductosMktMaterials/ProdMktMaterials';
import ProdSignsAndBanners from '../componentes/ProductosSignsAndBanners/ProdSignsAndBanners';


const Contenedor = styled.div``

const Title = styled.h1`
margin: 20px;
margin-bottom: 30px;
`
const Titulo = styled.h2`
margin: 20px;
`

const Productos = () => {
  return (
    <Contenedor>
        <Navbar/>
        <MenuNavbar/>
        <Title>All Products</Title>
        <Titulo>Marketing Materials</Titulo>
        <ProdMktMaterials/>
        <Titulo>Signs and Banners</Titulo>
        <ProdSignsAndBanners/>
        <Titulo>Apparel</Titulo>
        <ProdApparel/>
        <Titulo>Webpress Printing</Titulo>
        <ProdWebpressPrinting/>
        <Titulo>Invitations and Stationery</Titulo>
        <ProdInvitations/>
        <Newsletter/>
        <PieDePagina/>
    </Contenedor>
  )
}

export default Productos;