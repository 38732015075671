import styled from "styled-components";

//Se exportarán estas constantes a 'MenuNavbar'
//cada constante contiene propiedades de CSS que darán estilo
export const Contenedor = styled.div`
width: 100%;
height: 80px;
`
export const Wrapper = styled.div`
width: 100%;
max-width: 1300px;
height: 100%;
display: flex;
flex-wrap: wrap;
justify-content: space-between; 
margin: auto;
align-items: center;

`
export const Menu = styled.ul`
height: 100%;
display: flex;
justify-content: space-between;
list-style: none;

`
export const MenuItem = styled.li`
height: 100%;

`
export const MenuItemLink = styled.a`
display: flex;
margin: 5px;
justify-content: center;
align-items: center;
height: 100%;
padding: 1rem 1rem;
font-size: 16px;
font-weight: bold;
cursor: pointer;
transition: 0.5s all ease;

&:hover{
    color: #819FF7;
    transition: 0.5s all ease;
}

`

//propiedades del ícono de Navbar responsivo
export const MobileIcon = styled.div`
display: none;

`