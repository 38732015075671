import React, {useState} from 'react';
import styled from "styled-components";
import { ArrowLeftOutlined, ArrowRightOutlined } from '@material-ui/icons';
import {elementosSlider} from "../data";


const Contenedor = styled.div`
width: 100%;
height: 100vh;
display: flex;
position: relative;
overflow: hidden;
`
const Flechas = styled.div`
width: 50px;
height: 50px;
background-color: white;
border-radius: 50%;
display: flex;
align-items: center;
justify-content: center;
position: absolute;
top: 0;
bottom: 0;
left: ${props=> props.direction === "left" && "10px"};
right: ${props=> props.direction === "right" && "10px"};
margin: auto;
cursor: pointer;
opacity: 0.5;
z-index: 2;
`

const Wrapper = styled.div`
height: 100%;
display: flex;
transition: all 1.5s ease;
transform: translateX(${props=>props.slideIndex * -100}vw);

`
const Deslizador = styled.div`
width: 100vw;
height: 100vh;
display: flex;
align-items: center;
background-color: #${props => props.bg}
`
const ImagenContenedor = styled.div`
height: 80%;
flex: 1;
`
const Imagen = styled.img`
height: 100%;
`

const Titulo = styled.h1`
font-size: 70px;
`
const Descripcion = styled.p`
margin: 50px 0px;
font-size:20px;
font-weight: 500;
letter-spacing: 3px;
`

const InfoContenedor = styled.div`
flex: 1;
padding: 50px;
`

const Slider = () => {

    const [slideIndex, setSlideIndex]= useState(0);
    const handleClick = (direction) => {
        if(direction === 'left'){
            setSlideIndex(slideIndex > 0 ? slideIndex-1 : 2 )
        }
        else{
            setSlideIndex(slideIndex < 2 ? slideIndex +1 : 0)
        }
    }
  return (
    <Contenedor>
        <Flechas direction="left" onClick={()=>handleClick("left")}>
            <ArrowLeftOutlined/>
        </Flechas>
        <Wrapper slideIndex = {slideIndex}>
            {elementosSlider.map(item =>(
        <Deslizador bg={item.bg} key={item.id}>
            <ImagenContenedor>
                <Imagen src= {item.imagen}/>
            </ImagenContenedor>
            <InfoContenedor>
                <Titulo>{item.titulo}</Titulo>
                <Descripcion>{item.descripcion}</Descripcion>
                
            </InfoContenedor>
        </Deslizador>
        ))}
        </Wrapper>
        <Flechas direction="right" onClick={()=>handleClick("left")}>
            <ArrowRightOutlined/>
        </Flechas>
    </Contenedor>
  )
}

export default Slider;
