import React from 'react';
import styled from "styled-components";
import Navbar from '../componentes/Navbar';
import MenuNavbar from '../componentes/BarraNavegacion/MenuNavbar';
import Newsletter from '../componentes/Newsletter/Newsletter';
import PieDePagina from '../componentes/PieDePagina';
import { useState } from 'react';
import ProdMktMaterials from '../componentes/ProductosMktMaterials/ProdMktMaterials';


const Contenedor = styled.div``
const Title = styled.h1`
margin: 20px;
`


const MarketingMaterials = () => {
  //configuración de filtros 
  const [filters, setFilters]=useState({});
  const [sort,setSort]= useState("newest");

  const handleFilters = (e) =>{
    const value = e.target.value;
    setFilters({
      ...filters,
      [e.target.name]: value,
    });
  };

  

  return (
    <Contenedor>
        <Navbar/>
        <MenuNavbar/>
        <Title>Marketing Materials</Title>
        <ProdMktMaterials/>
        <Newsletter/>
        <PieDePagina/>
    </Contenedor>
  );
}

export default MarketingMaterials;