import { createSlice } from "@reduxjs/toolkit";

const quoteSlice = createSlice({
    name:"quote",
    initialState:{
        products: [],
        quantity: 0,
    },
    reducers:{
        addProduct:(state, action) => {
            state.quantity += 1;
            state.products.push(action.payload);   
        },
    },
});

export const {addProduct} = quoteSlice.actions
export default quoteSlice.reducer;