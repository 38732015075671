import { collection, onSnapshot} from 'firebase/firestore';
import React, {useState, useEffect} from 'react';
import { db } from '../../firebase/firebaseConfig';
import styled from 'styled-components';
import {Link} from 'react-router-dom';

const Contenedor = styled.div`
padding: 20px;
display: flex;
flex-wrap: wrap;
justify-content: flex-start;
margin: 50px;
align-items: center;
`
const Boton = styled.button`
width: 100%;
border: none;
padding: 15px 20px;
color: Black;
background-color: #C9D7E4;
cursor: pointer;
margin-top: 20px;
`

export default function ProdInvitations() {
    const [products, setProducts] = useState([]);

    useEffect(() => {
        onSnapshot(collection(db, 'invitationsAndStationery'),
        (snapshot) => {
            //console.log(snapshot.docs[0].data());
            const products = snapshot.docs.map((documento) => {
                return {...documento.data(), id: documento.id}
            });
            setProducts(products);
        }
    );
    }, []);

    return(
        <Contenedor>
           {
            products.length === 0 ? (
                <p>No products found</p>
            ) : (
                products.map(({id,title,imgURL}) =>(
                    <div className='border mt-3 p-3' key={id} style={{margin: "30px"}}>
                        <div className='row'>
                            <div className='col-3'>
                                 <img src={imgURL} alt='title' style={{height: 200, width:240}}/>
                            </div>
                            <div className='col-9 ps-3' style={{textAlign: "center", marginTop: 5}}>
                                <h2>{title}</h2>
                            </div>
                            <Link to={`/invitationsAndStationery/`+ id}>
                            </Link>
                        </div>
                    </div>
                ))
            )
            
           }
        </Contenedor>
        
    ); 
}