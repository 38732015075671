import React from 'react';
import styled from "styled-components";
import Navbar from '../componentes/Navbar';
import MenuNavbar from '../componentes/BarraNavegacion/MenuNavbar';
import Newsletter from '../componentes/Newsletter/Newsletter';
import PieDePagina from '../componentes/PieDePagina';
import ProdApparel from '../componentes/ProductosApparel/ProdApparel';


const Contenedor = styled.div``
const Title = styled.h1`
margin: 20px;
`
const Filtros = styled.div`
display: flex;
`
const Filter = styled.div`
 margin: 20px;
`
const TextoFiltro = styled.div`
font-size: 20px;
font-weight: 600;
`
const Seleccionar = styled.select`
padding: 10px;
`
const Opcion = styled.option``

const Apparel = () => {
  return (
    <Contenedor>
        <Navbar/>
        <MenuNavbar/>
        <Title>Apparel</Title>
        <ProdApparel/>
        <Newsletter/>
        <PieDePagina/>
    </Contenedor>
  )
}

export default Apparel;