import React from 'react';

const langContext = React.createContext();

const LangProvider = ({children}) => {
    return ( 
        <langContext.Provider value={{hola: 'Hola, esta es una demostración'}}>
            {children}
        </langContext.Provider>
     );
}
 
export {LangProvider, langContext};