import React from 'react';
import styled from "styled-components";
import Navbar from '../componentes/Navbar';
import MenuNavbar from '../componentes/BarraNavegacion/MenuNavbar';
import Newsletter from '../componentes/Newsletter/Newsletter';
import PieDePagina from '../componentes/PieDePagina';
import ProdWebpressPrinting from '../componentes/ProductosWebpressPrinting/ProdWebpressPrinting';


const Contenedor = styled.div``
const Title = styled.h1`
margin: 20px;
`


const WebpressPrinting = () => {
  return (
    <Contenedor>
        <Navbar/>
        <MenuNavbar/>
        <Title>Webpress Printing</Title>
        <ProdWebpressPrinting/>
        <Newsletter/>
        <PieDePagina/>
    </Contenedor>
  )
}

export default WebpressPrinting