import React from 'react';
import styled from 'styled-components';
import {Search} from "@material-ui/icons";
import superiorPrintingLogo from '../Imagenes/img/superiorPrintingLogo.jpg';
import logoImpresora from '../Imagenes/img/logoImpresora.png';
import { useSelector } from "react-redux";


const Contenedor = styled.div`
    height: 90px;
    background-color: white;
`


const Wrapper = styled.div`
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const Izquierda = styled.div`
felx: 1;
display: flex;
`

const ContenedorBusqueda = styled.div`
border: none;
display: flex;
align-items: center;
margin-left: 25px;
padding: 5px;
`

const Input = styled.input`
width: 400px;
`

const Centro = styled.div`
felx: 1;
display: flex;

`

const LogoIngles = styled.img`
weight: 150px;
height: 75px;

`

const LogoEspanol = styled.img`
weight: 160px;
height: 80px;

`

const Derecha = styled.div`
felx:1;
display: flex;

`

const Navbar = () => {
  const quantity= useSelector(state => state.quote.quantity)

  return (
    <Contenedor>  
        <Wrapper>
            <Izquierda>
              <LogoIngles src={superiorPrintingLogo}/>
            </Izquierda> 
            <Centro>
              <ContenedorBusqueda>
                <Input placeholder="search"/>
                <Search/>
              </ContenedorBusqueda>    
            </Centro> 
            <Derecha> 
              <LogoEspanol src={logoImpresora}/>
            </Derecha>
        </Wrapper> 
    </Contenedor>
  
  )
}

export default Navbar;