import React from 'react';
import styled from "styled-components";
import Navbar from '../componentes/Navbar';
import MenuNavbar from '../componentes/BarraNavegacion/MenuNavbar';
import Newsletter from '../componentes/Newsletter/Newsletter';
import PieDePagina from '../componentes/PieDePagina';
import AllIndustries from '../componentes/IndustriesSection/AllIndustries';

const Contenedor = styled.div``
const Titulo = styled.h1`
margin: 20px;
text-align: center;
`
const Wrapper = styled.div``


const Industria = () => {
  return (
    <Contenedor>
        <Navbar/>
        <MenuNavbar/>
        <Wrapper>
        <Titulo>Find products and services for your business</Titulo>
        <AllIndustries/>
        </Wrapper>
        <Newsletter/> 
        <PieDePagina/>

    </Contenedor>
  )
}

export default Industria