import {React, useState} from 'react';
import {Contenedor, Wrapper, Menu, MenuItem, MenuItemLink, MobileIcon} from '../BarraNavegacion/MenuElementos';
import {FaBars} from "react-icons/fa";
import { IconContext } from 'react-icons';
import {Link} from "react-router-dom";

const MenuNavbar = () => {
const [showMobileMenu, setShowMobileMenu] = useState(false);

  return (
    <Contenedor>
        <Wrapper>
            <IconContext.Provider value = {{style: {fontSize: "2em"}}}>
            <MobileIcon onClick = {() => setShowMobileMenu(!showMobileMenu)}>
                <FaBars/>
            </MobileIcon>
            <Menu open={showMobileMenu}>
                <MenuItem>
                    <Link style={{color: "black", textDecoration: "none"}} to= {'/'} >
                        <MenuItemLink>
                            Home
                        </MenuItemLink>
                    </Link>
                </MenuItem>
                <MenuItem>
                    <Link style={{color: "black", textDecoration: "none"}} to={'/products'}>
                        <MenuItemLink>
                            All products
                        </MenuItemLink>
                    </Link>
                </MenuItem>
                <MenuItem>
                    <Link style={{color: "black", textDecoration: "none"}} to={'/marketingMaterials'}>
                        <MenuItemLink>
                            Marketing Materials
                        </MenuItemLink>
                    </Link>
                </MenuItem>
                <MenuItem>
                    <Link style={{color: "black", textDecoration: "none"}} to={'/signsAndBanners'}>
                        <MenuItemLink>
                            Signs and Banners
                        </MenuItemLink>
                    </Link>
                </MenuItem>
                <MenuItem>
                    <Link style={{color: "black", textDecoration: "none"}} to={'/apparel'}>
                        <MenuItemLink>
                            Apparel
                        </MenuItemLink>
                    </Link>
                </MenuItem>
                <MenuItem>
                    <Link style={{color: "black", textDecoration: "none"}} to={'/webpressPrinting'}>
                        <MenuItemLink>
                            Webpress Printing
                        </MenuItemLink>
                    </Link>
                </MenuItem>
                <MenuItem>
                    <Link style={{color: "black", textDecoration: "none"}} to={'/invitationsAndStationery'}>
                        <MenuItemLink>
                            Invitations and Stationery
                        </MenuItemLink>
                    </Link>
                </MenuItem>
                <MenuItem>
                    <Link style={{color: "black", textDecoration: "none"}} to={'/industry'}>
                        <MenuItemLink>
                            Industry
                        </MenuItemLink>
                    </Link>
                </MenuItem>
                <MenuItem>
                    <Link style={{color: "black", textDecoration: "none"}} to={'/contactUs'}>
                        <MenuItemLink>
                            Contact Us
                        </MenuItemLink>
                    </Link>
                </MenuItem>
            </Menu>
            </IconContext.Provider>
        </Wrapper>
    </Contenedor>
  )
}

export default MenuNavbar