import { Facebook, Room, Phone, MailOutline } from '@material-ui/icons';
import React from 'react';
import styled from "styled-components";
import superiorPrintingLogo from '../Imagenes/img/superiorPrintingLogo.jpg';
import {Link} from 'react-router-dom';

const Contenedor = styled.div`
display: flex;
`
const Izquierdo = styled.div`
flex: 1;
display: flex;
flex-direction: column;
padding: 20px;
`
const Logo = styled.img`
width: 200px;
height: 75px;
margin-left:auto;
margin-right: auto;
`

const Descripcion = styled.p`
margin: 20px 0px;
text-align: center;
font-size: 18px;
`
const ContenedorRedSocial = styled.div`
flex: auto;
margin: auto;
`
const IconoRedSocial = styled.div`
width: 40px;
height: 40px;
border-radius: 50%;
color: white;
background-color: #${props=> props.color};
display: flex;
align-items: center;
justify-content: center;
`

const Centro = styled.div`
flex: 1;
padding: 20px;
`
const Titulo = styled.h3`
margin-bottom:30px;
`

const Lista = styled.ul`
margin: 0;
padding: 0;
list-style: none;
`

const ListaItem = styled.li`
width: 50%;
margin-bottom: 10px;
`

const Derecho = styled.div`
flex: 1;
padding: 20px;
`

const PanelContacto = styled.div`
flex: 1;
padding: 20px;
`

const ContactoItem = styled.div`
margin-bottom: 10px;
display: column;
align-items: center;
`
const LinkFacebook = styled.a`
`

const PieDePagina = () => {
  return (
    <Contenedor>
        <Izquierdo>
            <Logo src={superiorPrintingLogo}/>
            <Descripcion>
            Follow us on Facebook to see the latest news
            </Descripcion>
            <ContenedorRedSocial>
              <LinkFacebook href={'https://es-la.facebook.com/Impresora-Y-Papelera-Superior-1579073115711987/'}>
                <IconoRedSocial color="385999">
                  <Facebook/>
                </IconoRedSocial>
              </LinkFacebook>
            </ContenedorRedSocial>
        </Izquierdo>
        <Centro>
          <Titulo>Explore more</Titulo>
          <Lista>
            <Link style={{color: "black", textDecoration: "none"}} to={'/'}>
              <ListaItem>Home</ListaItem>
            </Link>
            <Link style={{color: "black", textDecoration: "none"}} to={'/products'}>
              <ListaItem>All Products</ListaItem>
            </Link>
            <Link style={{color: "black", textDecoration: "none"}} to={'/marketingMaterials'}>
              <ListaItem>Marketing Materials</ListaItem>
            </Link>
            <Link style={{color: "black", textDecoration: "none"}} to={'/signsAndBanners'}>
              <ListaItem>Signs and Banners</ListaItem>
            </Link>
            <Link style={{color: "black", textDecoration: "none"}} to={'/apparel'}>
              <ListaItem>Apparel</ListaItem>
            </Link>
            <Link style={{color: "black", textDecoration: "none"}} to={'/webpressPrinting'}>
              <ListaItem>Webpress Printing</ListaItem>
            </Link>
            <Link style={{color: "black", textDecoration: "none"}} to={'/invitationsAndStationery'}>
              <ListaItem>Invitations and Stationery</ListaItem>
            </Link>
            <Link style={{color: "black", textDecoration: "none"}} to={'/industry'}>
              <ListaItem>Industry</ListaItem>
            </Link>
            <Link style={{color: "black", textDecoration: "none"}} to={'/contactUs'}>
              <ListaItem>Contact Us</ListaItem>
            </Link>
          </Lista>
        </Centro>
        <Derecho>
          <Titulo>Our company</Titulo>
          <Lista>
            <ListaItem></ListaItem>
            <ListaItem>About Us</ListaItem>
            <ListaItem>Legal</ListaItem>
            <ListaItem>Privacy</ListaItem>
          </Lista>
        </Derecho>
        <PanelContacto>
          <Titulo>Contact</Titulo>
          <ContactoItem><Room style={{marginRight: "10px"}}/>
            676 Archibald St, San Luis, AZ 85349, EE. UU.
          </ContactoItem>
          <ContactoItem>
            Hidalgo y Calle 36, San Luis Río Colorado, Sonora, México.
          </ContactoItem>
          <ContactoItem><Phone style={{marginRight: "10px"}}/> 
            +1 (928)941-8255 (EE. UU.)
          </ContactoItem>
          <ContactoItem> 
            (653) 535-9397 / (653) 535-9398 (México)
          </ContactoItem>
          <ContactoItem><MailOutline style={{marginRight: "10px"}}/>
            mmonge@impresorasuperior.com (EE. UU.)
          </ContactoItem>
          <ContactoItem>
            viviana@impresorasuperior.com (México)
          </ContactoItem>
        </PanelContacto>
      </Contenedor>
  )
}

export default PieDePagina