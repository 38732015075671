import { Send } from '@material-ui/icons';
import React, {useState} from 'react';
import styled from "styled-components";
import Alerta from './elementos/Alerta';

const Contenedor = styled.div`
height: 60vh;
background-color: #EFF4FA;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
`
const Titulo = styled.h1`
font-size: 40px;
margin-bottom: 20px;
`
const Descripcion = styled.div`
font-size: 24px;
font-weight: 300;
margin-bottom: 20px;
`
const InputContenedor = styled.div`
width: 100%;
height: 40px;
background-color: white;
display: flex;
border: 1px solid lightgray;
position: center;
`
const Input = styled.input`
border: none;
flex: 8;
padding-left: 20px;
align-items:center;
width: 400px;
`
const Boton = styled.button`
flex: 1;
border: none;
background-color: #355C91;
color: white;
cursor: pointer;
`
const Forma = styled.form`
align-items: center;

`


const Informes = () => {

  const [email, establecerEmail] = useState('');
  const [estadoAlerta, cambiarEstadoAlerta] = useState(false);
  const [alerta, cambiarAlerta] = useState({});

  const handleChange = (e) => {
    switch(e.target.name){
      case 'email':
        establecerEmail(e.target.value);
        break;
      default:
        break;
    }
  }

  function handleSubmit(e){
    e.preventDefault();
    cambiarEstadoAlerta(false);
    cambiarAlerta({});

  //validación de correo electrónico
  const expresionRegular = /[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+/;
    if (!expresionRegular.test(email)){
      cambiarEstadoAlerta(true);
      cambiarAlerta({
        tipo: 'error',
        mensaje: 'Please enter a valid email'
      });
      return;

    }

  }

  return (
    <Contenedor>
        <Titulo>Newsletter</Titulo>
        <Descripcion>Get more information about our products and services</Descripcion>
        <Forma onSubmit={handleSubmit}>
         <InputContenedor>
              <Input 
                type="email"
                name="email"
                placeholder="Email"
                value={email}
                onChange={handleChange}
              />
              <Boton type="submit">
                  <Send/>
              </Boton>
         </InputContenedor>
         </Forma>
         <Alerta
                tipo ={alerta.tipo}
                mensaje ={alerta.mensaje}
                estadoAlerta = {estadoAlerta}
                cambiarEstadoAlerta = {cambiarEstadoAlerta}
         />
    </Contenedor>
  )
}

export default Informes;